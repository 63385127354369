import UsuarioService, { StatusModulo, UsuarioDeleteInput, UsuarioDoLicenciadoOutput, UsuarioOutput } from '../../services/UsuarioService';
import Button from '../../components/button/button';
import Voltar from '../../components/voltar/voltar';
import Spinner from '../../components/spinner/spinner';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from '../../components/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/modal/modal';
import UsuarioEdicao from './usuario-edicao/usuario-edicao';
import Feedback from '../../components/feedback/feedback';
import Action from '../../components/action/action';
import ErroDeNegocio from '../../errors/ErroDeNegocio';
import { TableHeader } from '../../components/table/table.types';
import useAppFeedback from '../../hooks/use-app-feedback';
import UsuarioLocalStorageService from '../../services/UsuarioLocalStorageService';
import SessaoUsuarioContext from '../../contexts/SessaoUsuarioContext';


const styles = {
  table: {
    columns: { 'Categoria': { width: '150px' }, 'Ações': { width: '80px' } }
  }
}

const Usuario = () => {
  const headers = [
    'Nome',
    'Email',
    'Categoria',
    {
      property: 'modulos',
      name: 'Módulos',
      getter: (value: UsuarioOutput) => value.modulos
        .filter(u => u.status)
        .map(u => u.nome.toUpperCase())
        .join(', ') || ' '
    } as TableHeader,
    'Ações'
  ];
  const navigate = useNavigate();

  const { setSessao } = useContext(SessaoUsuarioContext);
  const [usuarios, setUsuarios] = useState<UsuarioOutput[]>([]);
  const [loading, setLoading] = useState(false);
  const [estaEditando, setEstaEditando] = useState(false);
  const [usuarioParaAtualizar, setUsuarioParaAtualizar] = useState<UsuarioOutput>();
  const { feedbacks, adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, limparFeedbacks, removerFeedback } = useAppFeedback();

  const cadastrarNovoUsuario = useCallback(() => {
    limparFeedbacks();
    return navigate('/usuario/novo');
  }, [limparFeedbacks, navigate]);


  const atualizaUsuarioSessao = useCallback(() => {
    (async () => {
      const usuarioslicenciados = await UsuarioService.obterInformacoesDoEmailDaSessao();
      const usuarioStorage = UsuarioLocalStorageService.obter();
      var usuarioLogado = usuarioslicenciados.find(x => x.id === usuarioStorage?.id);

      const novoUsuarioStorage = { ...usuarioStorage }
      novoUsuarioStorage.categoria = usuarioLogado?.categoria;
      novoUsuarioStorage.categoriaNome = usuarioLogado?.categoriaNome

      if (setSessao) {
        UsuarioLocalStorageService.atribuir(novoUsuarioStorage as UsuarioDoLicenciadoOutput);
        setSessao(novoUsuarioStorage as UsuarioDoLicenciadoOutput);
      }

    })();
  }, [setSessao]);

  const obterlistaDeUsuarios = useCallback(async () => {
    limparFeedbacks();
    setLoading(true)
    try {

      var listaDeUsuarios = await UsuarioService.obterUsuarios();
      const usuarios = listaDeUsuarios.content.usuarios;
      if (!usuarios.length) {
        setLoading(false);
        return;
      }

      const listaDeUsuariosAjustadas = usuarios.map(usuario => ({
        ...usuario,
        categoria: usuario.categoria.toString() === "0" ? "Administrador" : "Comum",
        statusModulos: usuario.modulos.reduce((prev, curr) => {
          const val = {} as { [key: string]: boolean };
          val[curr.nome.toLowerCase()] = curr.status;
          prev = { ...prev, ...val };
          return prev;
        }, { pessoal: false, rh: false } as StatusModulo)
      }));

      setUsuarios(listaDeUsuariosAjustadas);
    } catch (error) {
      const message = (error as ErroDeNegocio)?.mensagem || (error as Error).message;
      adicionarFeedbackDeErro(message);
      atualizaUsuarioSessao();
    }
    setLoading(false);
  }, [adicionarFeedbackDeErro, limparFeedbacks, atualizaUsuarioSessao]);

  const editarUsuario = useCallback(async (usuario: UsuarioOutput): Promise<boolean> => {
    limparFeedbacks();
    setEstaEditando(true);
    setUsuarioParaAtualizar(usuario);
    return false;
  }, [limparFeedbacks])

  const removerUsuario = useCallback((async (usuario: UsuarioOutput): Promise<boolean> => {
    limparFeedbacks();
    setLoading(true)
    try {
      const usuarioDelete: UsuarioDeleteInput = {
        UsuarioId: usuario.id
      }
      await UsuarioService.deleteUsuario(usuarioDelete);
      adicionarFeedbackDeSucesso('Usuario removido com Sucesso!')
      obterlistaDeUsuarios();
    } catch (error) {
      const message = (error as ErroDeNegocio)?.mensagem || (error as Error).message;
      adicionarFeedbackDeErro(message);
    }
    setLoading(false);
    return true;
  }), [adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, limparFeedbacks, obterlistaDeUsuarios]);

  const cancelarAtualizacao = useCallback(() => {
    limparFeedbacks();
    setEstaEditando(false)
    setUsuarios([]);
  }, [limparFeedbacks]);

  useEffect(() => {
    if (!usuarios.length)
      obterlistaDeUsuarios();
  }, [obterlistaDeUsuarios, usuarios]);

  useEffect(() => {
    return () => limparFeedbacks();
  }, [limparFeedbacks]);

  return (
    <>
      <h1 className="top-title"><Voltar />Lista de Usuarios</h1>
      <div className='content-container'>
        {!estaEditando && <Feedback feedbacks={feedbacks} remover={removerFeedback} />}
        {loading && <div className='loading'>
          <Spinner size={170} />
        </div>}
        <Button classes="submit-button" style={{ margin: '5px' }}
          text={<><FontAwesomeIcon icon={faPlus} /> Novo</>}
          onClick={() => cadastrarNovoUsuario()}></Button>
        <Table.Root>
          <Table.Header headers={headers} columnStyles={styles.table.columns}></Table.Header>
          <Table.Body
            headers={headers}
            data={usuarios}
            actions={(value) => <>
              <Action title='Editar' icon={faPenToSquare} value={value} onRowClick={editarUsuario} />
              <Action title='Remover' icon={faTrash} value={value} onRowClick={removerUsuario}
                confirm
                confirmMessage={<>
                  Tem certeza que deseja excluir o usuário:<br /><br />
                  <strong>{(value as UsuarioOutput).nome}<br />{(value as UsuarioOutput).email}</strong><br />
                  ?
                </>} />
            </>}
          />
        </Table.Root>
      </div>
      {<Modal isOpened={estaEditando}>
        <UsuarioEdicao usuarioParaAtualizar={usuarioParaAtualizar as UsuarioOutput} clickCancelar={cancelarAtualizacao} />
      </Modal>}
    </>
  );
}

export default Usuario;